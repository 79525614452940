import "./Header.css";

function Header() {
  return (
    <div>
      <header className="Header">
        <h1>wordle</h1>
      </header>
    </div>
  );
}

export default Header;
